import iconComponents from '@/assets/icons/iconComponents'
import { MenuItem } from './dashboard.types'
import { Pages } from '@/routes/route.constant'
import Chats from './components/header/Chats'

export const MenuLists: MenuItem[] = [
  {
    name: 'Business Discovery',
    icon: iconComponents.navigation.NetworkIcon,
    url: `/app/${Pages.StartupDiscovery}`,
    hasPermission: true,
    permissionKey: 'Can View Businesses',
  },
  {
    name: 'Events',
    icon: iconComponents.navigation.EventIcon,
    url: '/app/events',
    hasPermission: false,
  },

  {
    name: 'Community',
    icon: iconComponents.navigation.TeamIcon,
    url: '/app/community',
    hasPermission: true,
    permissionKey: 'Can View Community',
  },
  {
    name: 'The Network',
    icon: iconComponents.navigation.NetworkIcon,
    url: '/app/networks',
    hasPermission: false,
  },
  {
    name: 'My Portfolio',
    icon: iconComponents.navigation.FileIcon,
    url: `/app/${Pages.Portfolio}`,
    hasPermission: true,
    permissionKey: 'Can View Portfolios',
  },
  // {
  //   name: 'Funds',
  //   icon: iconComponents.navigation.FundsIcon,
  //   url: `/app/${Pages.Funds}`,
  //   hasPermission: true,
  //   permissionKey: 'Can View Funds',
  // },
  {
    name: 'Deal Flow',
    icon: iconComponents.navigation.DealFlowIcon,
    url: `/app/${Pages.DealFlow}`,
    hasPermission: true,
    permissionKey: 'Can View Deals',
  },
  {
    name: 'Message',
    icon: Chats,
    url: '/app/messages',
    hasPermission: true,
    permissionKey: 'Can Receive and Reply Messages',
  },
  {
    name: 'Profile',
    icon: iconComponents.navigation.ProfileIcon,
    url: `/app/${Pages.Profile}`,
    hasPermission: false,
  },
  {
    name: 'User Roles & Permissions',
    icon: iconComponents.navigation.RolesIcon,
    url: `/app/${Pages.UsersRoles}`,
    hasPermission: true,
    permissionKey: 'Can View User Roles & Permissions',
  },
]
